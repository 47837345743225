import { Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import { Subset } from '@pentair/react-mui-5/build/@types/objects';
import { IconCheck, IconSpinner } from '@pentair/react-tailwind/ui/icon';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogHeader } from '../../../theme/components/dialog-header';
import { PentairTextField } from '../../../theme/components/pentair-text-field';
import { InputQuotation } from '../../../types/operations';
import { useShoppingCart } from '../shopping-cart/hook/useShoppingcart';
import { useQuotationForm } from './hooks/use-quotation-form';

interface PoNumberDialogProps {
  active: boolean;
  setActive: any;
  inputQuotation: Subset<InputQuotation>;
}

export const PoNumberDialog: React.FC<PoNumberDialogProps> = ({ active, setActive, inputQuotation }) => {
  const { t } = useTranslation();
  const { cart } = useShoppingCart();
  const { cartPdf, downloadCartPdf, change, validateRecord, record } = useQuotationForm();

  useEffect(() => {
    change(inputQuotation);
  }, [inputQuotation, change]);

  const handleDownloadCartPdf = useCallback(async () => {
    const errors = validateRecord(record, true);
    if (Object.keys(errors).length > 0) {
      console.log(errors);
      return;
    }
    if (cart && cart.items.length > 0) {
      downloadCartPdf({ input: record as any }).then((res) => {
        if (res.data?.createCartPdf) {
          const link = document.createElement('a');
          link.href = res.data.createCartPdf;
          link.download = 'cart.pdf';
          link.click();
        }

        setActive(false);
      });
    }
  }, [cart, record, validateRecord, downloadCartPdf, setActive]);

  return (
    <Dialog
      open={active}
      fullWidth={true}
      PaperProps={{ style: { padding: 0, maxWidth: 420 } }}
      slotProps={{ backdrop: { style: { backdropFilter: 'blur(8px)' } } }}
      onClose={() => setActive(false)}
    >
      <DialogHeader title={'Enter PO number'} onClose={() => setActive(false)} />
      <DialogContent>
        <Typography paragraph>Please enter the PO number for this order</Typography>

        <PentairTextField
          type="text"
          label={'PO number'}
          variant="standard"
          placeholder="Enter PO number"
          margin={'dense'}
          onChange={(e) => change('PONumber', e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={() => setActive(false)} color="secondary">
          {t('cancel', { defaultValue: 'Cancel' })}
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={cartPdf?.loading}
          startIcon={cartPdf?.loading ? <IconSpinner /> : <IconCheck />}
          onClick={() => handleDownloadCartPdf()}
        >
          Export to PDF
        </Button>
      </DialogActions>
    </Dialog>
  );
};
