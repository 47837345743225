import { Box, Dialog, DialogContent, Link, Typography, styled } from '@mui/material';

import { useTranslation } from 'react-i18next';
import { DialogHeader } from '../../theme/components/dialog-header';
import { PentairHeading } from '../../theme/components/pentair-heading';
import supportImage from './support_header.png';

const SupportImage = styled(Box)({
  width: 'calc(100% + 48px)',
  height: 120,
  backgroundImage: `url('${supportImage}')`,
  backgroundSize: 'cover',
  marginLeft: -24,
  marginRight: -24,
  marginBottom: 24,
});

interface SupportDialogProps {
  active: boolean;
  setActive: any;
}

export const ContactItem: React.FC<{ title: string; email: string; phone?: string }> = ({
  title,
  email,
  phone,
}) => {
  const { t } = useTranslation();

  return (
    <Box display="flex" flexDirection="column" gap={0.5}>
      <Typography style={{ fontWeight: 600 }}>{title}</Typography>
      <Box display="grid" gridTemplateColumns={["1fr" ,"1fr 1fr"]} gap={1}>
        <Box>
          <Typography>{t('support:dialog.contact.email.label', { defaultValue: 'Email address' })}</Typography>

          <Typography color={'secondary'}>
            <strong>
              <Link href={`mailto:${email}`}>{email}</Link>
            </strong>
          </Typography>
        </Box>

        {phone && (
          <Box>
            <Typography>{t('support:dialog.contact.phoneNumber.label', { defaultValue: 'Phone number' })}</Typography>

            <Typography color={'secondary'} gutterBottom>
              <strong>
                <Link href={`tel:${phone}`} color="secondary">
                  {phone}
                </Link>
              </strong>
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export const SupportDialog: React.FC<SupportDialogProps> = ({ active, setActive }) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={active}
      fullWidth={true}
      PaperProps={{ style: { padding: 0, maxWidth: 420 } }}
      BackdropProps={{ style: { backdropFilter: 'blur(8px)' } }}
      onClose={() => setActive(false)}
    >
      <DialogHeader
        title={t('support:dialog.contact.title', { defaultValue: 'Support' })}
        subtitle={t('support:dialog.contact.subtitle', { defaultValue: 'Get in touch' })}
        onClose={() => setActive(false)}
      />
      <DialogContent>
        <SupportImage />
        <PentairHeading paragraph>
          {t('support:dialog.contact.heading.contactUs', { defaultValue: 'Contact us' })}
        </PentairHeading>

        <Typography paragraph>
          {t('support:dialog.contact.text', {
            defaultValue:
              'Do you have questions or would you like to get more information? Contact our customer service representatives within your specific industry.',
          })}
        </Typography>

        <Box display="flex" flexDirection="column" gap={[2, 1]}>
          <ContactItem
            title={t('support:dialog.contact.europeRestOfWorld.title', {
              defaultValue: 'Europe & Rest of the world',
            })}
            email={t('support:dialog.contact.europeRestOfWorld.email.value', { defaultValue: 'info@suedmo.com' })}
            phone={t('support:dialog.contact.europeRestOfWorld.phoneNumber.value', { defaultValue: '+49 9081 803 0' })}
          />

          <ContactItem
            title={t('support:dialog.contact.usCanada.title', { defaultValue: 'US & Canada' })}
            email={t('support:dialog.contact.usCanada.email.value', { defaultValue: 'csudmo@pentair.com' })}
            phone={t('support:dialog.contact.usCanada.team.phoneNumber.value', { defaultValue: '+1 800 218 3915' })}
          />

          <ContactItem
            title={t('support:dialog.contact.australiaNewZealand.title', {
              defaultValue: 'Australia & New Zealand',
            })}
            email={t('support:dialog.contact.australiaNewZealand.email.value', {
              defaultValue: 'anz.service@pentair.com',
            })}
            phone={t('support:dialog.contact.australiaNewZealand.team.phoneNumber.value', {
              defaultValue: '+64 800 151 891',
            })}
          />

          <ContactItem
            title={t('support:dialog.contact.latam.title', { defaultValue: 'LATAM' })}
            email={t('support:dialog.contact.latam.email.value', { defaultValue: 'is.latam@pentair.com' })}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};
