import { faList } from '@fortawesome/pro-light-svg-icons';
import {
  Alert,
  Box,
  BoxProps,
  Button,
  Dialog,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { startTransition, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { DialogHeader } from '../../../theme/components/dialog-header';
import { LoadingIcon } from '../../../theme/components/loading-icon';
import { PentairHeading } from '../../../theme/components/pentair-heading';
import { TableCellDate, TableCellInfo, TableCellPrice } from '../../../theme/components/table-cells';
import { Cart } from '../../../types/operations';
import { Price } from '../../pricing/price';
import { usePricing } from '../../pricing/pricing-provider';
import { CartItemDescription } from '../shopping-cart/components/cart-item-description';
import { useFetchQuotation } from './api/fetch';
import { useFetchQuotationList } from './api/list';

export const QuotationTable = () => {
  const [{ response, error, loading }, fetchQuotations] = useFetchQuotationList();
  const { locale } = useParams<{ locale: string }>();
  const quotations = useMemo(() => response?.data?.listQuotations?.quotations || [], [response?.data]);

  const { t } = useTranslation();
  const [infoDialogOpen, setInfoDialogOpen] = useState(false);
  const [selectedQuotation] = useState<string | null>(null);

  // Hold current page
  const [currentPage, setCurrentPage] = useState<number>(1);

  // Check for medium screen and add spaces
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));

  // Hook to hide or show the pricing table row
  const { isUserAllowedToSeePrices } = usePricing();
  const navigate = useNavigate();

  const showQuotationInfo = useCallback(
    (quotationId: string) => {
      startTransition(() => {
        navigate(`/${locale}/quotations/${quotationId}/quotation-submitted`);
      });
    },
    [navigate, locale]
  );

  const paginatedQuotations = useMemo(() => {
    let perPage = 10;
    if (isSmallScreen) {
      perPage = 3;
    } else if (isMediumScreen) {
      perPage = 5;
    }

    const totalPages = Math.ceil(quotations.length / perPage);

    return {
      perPage,
      totalPages,
      isLastPage: currentPage === totalPages,
      list: quotations?.slice(0, currentPage * perPage),
    };
  }, [quotations, currentPage, isSmallScreen, isMediumScreen]);

  // handle the show more click event
  const handleShowMore = useCallback(() => {
    setCurrentPage((current) => current + 1);
  }, []);

  useEffect(() => {
    fetchQuotations({});
  }, [fetchQuotations]);

  if (loading) {
    return <LoadingIcon scale={0.75} />;
  }

  if (!quotations?.length) {
    return (
      <Typography>
        {t('ecommerce:emptyTable.quotations', { defaultValue: 'You have not requested any quotations yet.' })}
      </Typography>
    );
  }

  return (
    <>
      {error && <Alert severity="error">{error.message}</Alert>}
      <Box>
        <TableContainer
          sx={{
            /* TODO: Move to theme? */
            borderRight: isMediumScreen ? '3px solid #DADADA' : 'none',
          }}
        >
          <Table sx={{ marginBottom: 2 }}>
            <TableHead
              sx={{
                /* TODO: Move to theme? */
                whiteSpace: 'nowrap',
              }}
            >
              <TableRow>
                <TableCell>{t('ecommerce:tableHead.projectName')}</TableCell>
                <TableCell>{t('ecommerce:tableHead.country')}</TableCell>
                <TableCell>{t('date', { defaultValue: 'Date' })}</TableCell>
                {isUserAllowedToSeePrices && <TableCell align="right">{t('ecommerce:tableHead.price')}</TableCell>}
                <TableCellInfo disabled />
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedQuotations.list?.map((quotation) => (
                <TableRow key={quotation.id}>
                  <TableCell>
                    {quotation?.id.substring(0, 4)} - {quotation.projectName}
                  </TableCell>
                  <TableCell>{quotation.shippingInfo?.country}</TableCell>
                  <TableCellDate width={140} value={quotation.createdAt} />
                  {isUserAllowedToSeePrices && (
                    <TableCellPrice
                      align="right"
                      value={quotation?.cart?.pricing?.total}
                      valueDiscount={quotation?.cart?.pricing?.discountPrice}
                      currency={quotation?.cart?.pricing?.currency}
                    />
                  )}
                  <TableCellInfo
                    onClick={() => showQuotationInfo(quotation.id)}
                    label={` ${quotation?.id.substring(0, 4)} - ${quotation.projectName}`}
                  />
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Button
          variant="text"
          color="secondary"
          onClick={handleShowMore}
          sx={{
            margin: 'auto',
            display: paginatedQuotations?.isLastPage ? 'none' : 'block',
          }}
          disableElevation={true}
        >
          Show More
        </Button>
      </Box>
      <Dialog open={infoDialogOpen} fullWidth maxWidth={'sm'}>
        <DialogHeader
          title={t('ecommerce:dialog.quotationDetails.title', { defaultValue: 'Quotation' })}
          subtitle={t('ecommerce:dialog.quotationDetails.subtitle', { defaultValue: 'Details' })}
          onClose={() => setInfoDialogOpen(false)}
        />
        <DialogContent>
          <QuotationInfo quotationId={selectedQuotation} />
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={() => setInfoDialogOpen(false)} data-testid="close-quotation-details-button">
            Close
          </Button>
        </DialogActions> */}
      </Dialog>
    </>
  );
};

const QuotationInfo: React.FC<{ quotationId: string | undefined | null }> = ({ quotationId }) => {
  const { t } = useTranslation();
  const [{ response, loading, error }, fetchQuotation] = useFetchQuotation();
  const quotation = response?.data?.quotation;
  // const navigate = useNavigate();
  // const { locale } = useParams();

  const { isUserAllowedToSeePrices } = usePricing();

  useEffect(() => {
    if (quotationId) {
      fetchQuotation({ id: quotationId });
    }
  }, [fetchQuotation, quotationId]);

  if (loading) {
    return <LoadingIcon scale={0.75} />;
  }

  return (
    <>
      {error && <Alert severity="error">{error?.message}</Alert>}
      <Box>
        <PentairHeading marginTop={1} icon={faList}>
          {t('details', { defaultValue: 'details' })}
        </PentairHeading>
        <Box>
          <Box marginBottom={2}>
            <Typography component="strong" variant="body2" color="silverGray.main" fontWeight={600}>
              {t('ecommerce:projectName.label')}:
            </Typography>
            <Typography variant="body2">{quotation?.projectName || 'No name provided.'}</Typography>
          </Box>
          <Box marginBottom={2}>
            <Typography component="strong" variant="body2" color="silverGray.main" fontWeight={600}>
              {t('ecommerce:projectDescription.label', { defaultValue: 'Project description' })}:
            </Typography>
            <Typography variant="body2">
              {quotation?.projectDescription ||
                t('ecommerce:projectDescription.empty', { defaultValue: 'No description provided.' })}
            </Typography>
          </Box>

          <Box sx={{ display: 'inline-block', minWidth: 228, marginBottom: 2 }}>
            <Typography component="strong" variant="body2" color="silverGray.main" fontWeight={600}>
              {t('ecommerce:projectLocation.label', { defaultValue: 'Project location' })}:
            </Typography>
            <Typography variant="body2">
              {quotation?.shippingInfo.addressLine1}
              <br />
              {quotation?.shippingInfo.postal} {quotation?.shippingInfo.city}
              <br />
              {quotation?.shippingInfo?.country}
            </Typography>
          </Box>

          <Box sx={{ display: 'inline-block', minWidth: 276, marginBottom: 2 }}>
            <Typography component="strong" variant="body2" color="silverGray.main" fontWeight={600}>
              {t('ecommerce:projectContact.label', { defaultValue: 'Project contact' })}:
            </Typography>

            <Typography variant="body2">
              {quotation?.shippingInfo.firstName} {quotation?.shippingInfo.lastName}
              <br />
              {quotation?.shippingInfo.email}
              <br />
              {quotation?.shippingInfo.phone1}
            </Typography>
          </Box>
        </Box>

        <PentairHeading icon={faList} sx={{ marginBottom: 2, marginTop: 0 }}>
          {t('ecommerce:heading.requestedItems', { defaultValue: 'Requested items' })}:
        </PentairHeading>

        {isUserAllowedToSeePrices && (
          <Typography
            variant="body2"
            textTransform="uppercase"
            fontWeight="600"
            color={'silverGray.main'}
            marginBottom={2}
          >
            {t('customerId', { defaultValue: 'Customer ID' })}: {'  '}
            <Typography variant="body2" component="span" fontWeight="600" color="secondary.main">
              {quotation?.customerId}
            </Typography>
          </Typography>
        )}

        <Table>
          <TableBody>
            {quotation?.cart.items?.map((item) => (
              <TableRow sx={{ verticalAlign: 'top' }} key={item.sku}>
                <TableCell sx={{ borderTop: '1px solid', borderTopColor: '#DADADA' }}>{item?.quantity}x</TableCell>
                <TableCell sx={{ borderTop: '1px solid', borderTopColor: '#DADADA' }}>
                  <CartItemDescription title={item.sku + ' - ' + item.title} cartItem={item} />
                </TableCell>
                {isUserAllowedToSeePrices && (
                  <TableCell sx={{ borderTop: '1px solid', borderTopColor: '#DADADA', textAlign: 'right' }}>
                    <Price price={item?.pricing?.total} currency={item?.pricing?.currency} showTaxingInfo={false} />
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {isUserAllowedToSeePrices && <TablePriceSummaries cart={quotation?.cart} sx={{ marginTop: 1.5 }} />}
      </Box>
    </>
  );
};

/**
 * Summarizes the total prices
 */
export const TablePriceSummaries: React.FC<{ cart: Cart | undefined; sx?: BoxProps['sx'] }> = ({ cart, sx }) => {
  const { t } = useTranslation();

  const { isUserAllowedToSeePrices } = usePricing();

  if (!isUserAllowedToSeePrices) {
    return null;
  }

  return (
    <Box display={'flex'} gap={1} paddingRight={'1em'} flexDirection={'column'} alignItems="flex-end" sx={sx}>
      <PriceSummary
        title={t('subTotal', { defaultValue: 'Subtotal' })}
        value={cart?.pricing?.subTotal}
        currency={cart?.pricing?.currency}
      />
      {Boolean(cart?.pricing?.discountPrice) && (
        <PriceSummary
          title={t('discount', { defaultValue: 'Discount' })}
          value={cart?.pricing?.discountPrice}
          isNegative
          currency={cart?.pricing?.currency}
        />
      )}
      {Boolean(cart?.pricing?.handlingFee) && (
        <PriceSummary
          title={t('handlingFee', { defaultValue: 'Handling fee' })}
          value={cart?.pricing?.handlingFee}
          currency={cart?.pricing?.currency} 
        />
      )}
      {/* <PriceSummary title={t('shippingCost', { defaultValue: 'Shipping cost' })} value={cart?.pricing?.shippingCost} /> */}
      <PriceSummary
        title={t('total', { defaultValue: 'Total' })}
        value={cart?.pricing?.total}
        isBold={true}
        currency={cart?.pricing?.currency}
      />
    </Box>
  );
};

type PriceSummaryProps = {
  /** The amount of money to be displayed */
  value: number | undefined | null;
  /** The name of the field that will be displayed */
  title: string;
  /** Use this to make the reported value to be bolded  */
  isBold?: boolean;
  /** Style the container of the PriceSummary */
  sx?: BoxProps['sx'];
  /** Use this to add a negative sign to an amount */
  isNegative?: boolean;
  /** The currency of the price */
  currency?: string | null;
};

export const PriceSummary: React.FC<PriceSummaryProps> = ({ value, title, isBold, sx, isNegative, currency }) => {
  return (
    <Box sx={{ display: 'flex', width: 260, whiteSpace: 'nowrap', alignItems: 'center', ...sx }}>
      <Typography variant="h5" sx={{ textAlign: 'left', textTransform: 'unset' }}>
        {title}
      </Typography>
      <Typography
        variant="body2"
        sx={{
          justifySelf: 'flex-end',
          width: '100%',
          textAlign: 'right',
          color: 'silverGray.main',
          fontWeight: isBold ? '600' : '400',
        }}
      >
        <Price price={value} currency={currency} showTaxingInfo={false} />
      </Typography>
    </Box>
  );
};
