import { GetSessionCommandOutput, Message } from '@aws-sdk/client-lex-runtime-v2';
import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { SlotName, ValveSelectorEnrichtmentData, useEnrichmentData } from './enrichtment-data';
import { SuedmoAssistantLexBotProvider } from './helpers/lexbot-provider';

export type ValveInfo = {
  name: string;
  family: string;
  series: string;
  class: string;
  housingType: string | string[];
  isHygienic: boolean;
  isAseptic: boolean;
  isTankBottom: boolean;
  isMixProof: boolean;
  isManual: boolean;
  isPneumatic: boolean;
  isChangeover: boolean;
  isBV: boolean;
  isSVP: boolean;
  isNC: boolean;
  isAA: boolean;
  isDistributing: boolean;
  isMixing: boolean;
  isSeatliftingCleaning: boolean;
  isNoCleaning: boolean;
  isExternalCleaning: boolean;
  isSterilization: boolean;
  isPMO: boolean;
  isDirectConnection: boolean;
};

type SelectorResult = {
  valve: ValveInfo;
  class: string;
  series: string;
  family: string;
  housingType: string;
};

export const SuedmoAssistant = new SuedmoAssistantLexBotProvider();
SuedmoAssistant.configure({
  [process.env.REACT_APP_SuedmoProductAssistantBotName || '']: {
    name: process.env.REACT_APP_SuedmoProductAssistantBotName || '',
    botId: process.env.REACT_APP_SuedmoProductAssistantBotId || '',
    aliasId: process.env.REACT_APP_SuedmoProductAssistantBotAliasId || '',
    localeId: 'en_US',
    region: 'us-east-1',
    providerName: 'AWSLexV2Provider',
  },
});

const SuedmoAssistantContext = createContext<{
  questionsAnsweredCount: number;
  sessionAttributes: {
    matchingValves: string[];
    matchingValvesCount: number;
    values: Record<string, string>;
    valves: ValveInfo[];
  };
  messages: Message[];
  results: Record<string, any>;
  resetAssistant: () => Promise<void>;
  sendMessage: (message: string) => void;
  isLoading: boolean;
  isCompleted: boolean;
  revertAssistantToHistoricalState: (index: number) => void;
  history: GetSessionCommandOutput[];
  currentSlot: string | undefined;
  enrichmentData: ValveSelectorEnrichtmentData | undefined;
  result: SelectorResult | null;
  setResult: Dispatch<SetStateAction<SelectorResult | null>>;
  conversationStarted: boolean;
} | null>(null);

const parseSessionAttributes = (sessionAttributes: any) => {
  return {
    matchingValves: JSON.parse(sessionAttributes?.matchingValves || '[]'),
    matchingValvesCount: parseInt(sessionAttributes?.matchingValvesCount, 10),
    values: JSON.parse(sessionAttributes?.values || '{}'),
    valves: JSON.parse(sessionAttributes?.valves || '[]'),
  };
};

export const SuedmoAssistantProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation();

  const [history, setHistory] = useState<GetSessionCommandOutput[]>([]);
  const currentState: GetSessionCommandOutput | undefined = history.slice(-1)[0];
  const messages = currentState?.messages || [];
  const currentSlot = currentState?.sessionState?.dialogAction?.slotToElicit;

  const [isLoading, setIsLoading] = useState(true);
  const isCompleted = currentState?.sessionState?.intent?.state === 'Fulfilled';

  const conversationStarted = useRef(false);
  const sessionAttributes = useMemo(
    () => parseSessionAttributes(currentState?.sessionState?.sessionAttributes),
    [currentState?.sessionState?.sessionAttributes]
  );

  const [result, setResult] = useState<SelectorResult | null>(null);

  const results = useMemo(() => {
    return history.reduce((res, state, index) => {
      const slot = history[index]?.sessionState?.dialogAction?.slotToElicit;
      const prevSlot = history[index - 1]?.sessionState?.dialogAction?.slotToElicit;
      const message = history[index]?.messages?.[0]?.imageResponseCard?.title;

      if (slot) {
        res[slot] = { slot, message, index };
      }

      if (prevSlot) {
        const value = state.sessionState?.intent?.slots?.[prevSlot]?.value?.interpretedValue;
        res[prevSlot] = { ...res[prevSlot], value };
      }

      return res;
    }, {} as any);
  }, [history]);

  const sendMessage = useCallback(async (input: string) => {
    setIsLoading(true);
    try {
      const response = await SuedmoAssistant.sendMessage('SuedmoProductAssistant', input);
      setHistory((prev) => [...prev, response as GetSessionCommandOutput]);
    } catch (err) {
      console.error(err);
    }
    conversationStarted.current = true;
    setIsLoading(false);
  }, []);

  const resetAssistant = useCallback(async () => {
    setIsLoading(true);
    try {
      await SuedmoAssistant.deleteSession('SuedmoProductAssistant');
      setResult(null);
    } catch (err) {
      setIsLoading(false);
      console.error(err);
    }

    setHistory([]);
    await sendMessage('start');
  }, [sendMessage]);

  const revertAssistantToHistoricalState = useCallback(
    async (index: number) => {
      const sessionState = history[index]?.sessionState;

      if (sessionState) {
        await SuedmoAssistant.putSession('SuedmoProductAssistant', sessionState);
        setHistory(history.slice(0, index + 1));
        setResult(null);
      }
    },
    [history]
  );

  const enrichmentData = useEnrichmentData(t)[currentSlot as SlotName];

  useEffect(() => {
    if (results.isSampleValve?.value === 'yes') {
      setResult({
        valve: sessionAttributes.valves[0],
        class: sessionAttributes.valves[0]?.class,
        series: sessionAttributes.valves[0]?.series,
        family: sessionAttributes.valves[0]?.family,
        housingType: sessionAttributes.valves[0]?.housingType,
      });
    }
  }, [results, sessionAttributes.valves]);

  return (
    <SuedmoAssistantContext.Provider
      value={{
        questionsAnsweredCount: Object.values(results).filter((v: any) => v.input).length,
        sessionAttributes,
        results,
        messages,
        history,
        isLoading,
        isCompleted,
        revertAssistantToHistoricalState,
        sendMessage,
        resetAssistant,
        currentSlot,
        enrichmentData,
        result,
        setResult,
        conversationStarted: conversationStarted.current,
      }}
    >
      {children}
    </SuedmoAssistantContext.Provider>
  );
};

export const useValveAssistant = () => {
  const context = useContext(SuedmoAssistantContext);
  if (!context) {
    throw new Error('useValveAssistant must be used within a SuedmoAssistantProvider');
  }
  return context;
};

export default SuedmoAssistantProvider;
