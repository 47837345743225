import { TableCell, TableRow, TextField, TextFieldProps } from '@mui/material';
import { styled } from '@mui/system';
import { TableCellDelete, TableCellEdit, TableCellInfo } from '@pentair/react-mui-5';
import { isNil, omitBy } from 'lodash';
import { FocusEvent, startTransition, useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { TableCellPrice } from '../../../theme/components/table-cells';
import { CartItem } from '../../../types/operations';
import { CartItemDescription } from './components/cart-item-description';
import { CartItemNote } from './components/cart-item-note';
import { useShoppingCart } from './hook/useShoppingcart';

interface ShoppingCartItemProps {
  cartItem: CartItem;
}

export const ShoppingCartItem: React.FC<ShoppingCartItemProps> = ({ cartItem }) => {
  const { id, sku } = cartItem;
  const { removeCartItems, updateCartItem, isLoading, setShoppingCartOpen } = useShoppingCart();
  const [quantity, setQuantity] = useState(cartItem.quantity);
  const [note, setNote] = useState(cartItem.note || undefined);
  const navigate = useNavigate();
  const { locale } = useParams();

  const [isEditing, setIsEditing] = useState(false);

  const updateQuantity = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      const value = Number(e.target?.value) || 1;
      setQuantity(value);
      updateCartItem(id, { id: sku }, value);
    },
    [id, sku, updateCartItem]
  );

  const updateNote = useCallback(() => {
    setIsEditing(false);
    updateCartItem(id, { id: sku }, quantity, note);
  }, [id, note, quantity, sku, updateCartItem]);

  const showProductDetails = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      const filters = omitBy({ ...cartItem?.configuration }, isNil);
      let searchParams = new URLSearchParams(filters).toString();
      if (searchParams) {
        searchParams = `?${searchParams}`;
      }

      setShoppingCartOpen(false);

      startTransition(() => {
        navigate(`/${locale}/valve/${e.currentTarget.value || ''}${searchParams}`);
      });
    },
    [cartItem?.configuration, locale, navigate, setShoppingCartOpen]
  );

  const handleEditCartItem = useCallback(() => {
    setIsEditing((current) => !current);
  }, []);

  return (
    <TableRow key={id} sx={{ verticalAlign: 'top' }}>
      <TableCell>
        <CartAmount
          disabled={isLoading}
          value={quantity || ''}
          onBlur={updateQuantity}
          onChange={(e) => setQuantity(Number(e.target.value))}
        />
      </TableCell>
      <TableCell>
        <CartItemDescription cartItem={cartItem} title={[cartItem.sku, cartItem.title].filter(Boolean).join(' - ')} />
        <CartItemNote value={note || ''} editable={isEditing} onBlur={updateNote} onChange={setNote} />
      </TableCell>
      <TableCellPrice
        value={cartItem?.pricing?.total}
        valueDiscount={cartItem?.pricing?.discountPrice}
        currency={cartItem?.pricing?.currency}
      />
      {!cartItem.isMissingProductRequest ? (
        <TableCellInfo label={cartItem.title + ' - info'} value={cartItem.sku} onClick={showProductDetails} />
      ) : (
        <TableCell />
      )}
      <TableCellEdit label={cartItem.title} onClick={handleEditCartItem} />

      <TableCellDelete label={cartItem.title} onClick={() => removeCartItems([cartItem.id])} />
    </TableRow>
  );
};

// Hold cart amount and will accept handlers for increasing number of item in cart. Will be used for mobile too
export const CartAmount: React.FC<TextFieldProps> = (props) => {
  return (
    <CartItemAmount
      variant={'outlined'}
      size={'small'}
      margin="none"
      type={'number'}
      error={Number(props.value) === 0}
      {...props}
      inputProps={{
        min: 1,
        max: 99,
        ...props?.inputProps,
      }}
    />
  );
};

const CartItemAmount = styled(TextField)(({ theme }) => ({
  width: 60,
  input: {
    padding: '5px 8px',
  },

  [theme.breakpoints.down('md')]: {
    width: 50,
    height: 28,
  },
})) as typeof TextField;
