import gql from 'graphql-tag';
import { CreateCartPdfMutation, CreateCartPdfMutationVariables } from '../../../../types/operations';
import { ecommerceApi } from '../../../api/api';
import { makeApiHook } from '../../../api/helpers/make-api-hook';

const query = gql`
  mutation createCartPdf($input: InputQuotation!, $cartId: ID) {
    createCartPdf(input: $input, cartId: $cartId)
  }
`;

export const downloadCartPdf = async (variables: CreateCartPdfMutationVariables) => {
  try {
    return await ecommerceApi.query<CreateCartPdfMutation>(query, variables);
  } catch (err: any) {
    console.error('❌ GraphQL Error:', err);
    if (err instanceof Error) throw err;
    else throw new Error(err.toString());
  }
};

export const useDownloadCartPdf = makeApiHook(downloadCartPdf, {});
