import gql from 'graphql-tag';

export const productControlUnitFragment = gql`
  fragment ProductControlUnitFragment on Product {
    controlUnit {
      ...ProductFragment
    }
  }
`;

export const productMountingKitFragment = gql`
  fragment ProductMountingKitFragment on Product {
    mountingKit {
      ...ProductFragment
    }
  }
`;

const productCollectionItemFragment = gql`
  fragment ProductCollectionItemFragment on ProductCollectionItem {
    id
    name
    pricing {
      price
      currency
    }
    productType
    class
    series
    family
    housingType
    portConfiguration
    sealMaterial
    sealMaterialORing
    sizeUpper
    sizeLower
    materialKey
    descriptionValveInsertHousing
    operationPressureLabelLaser
    typeForLaser
    skuInactiveStatus
    materialInsert
    apzLb
    actuation
    actuationType
    amountOfSolenoids
    communicationProtocol
    connection
    mountingKitSku
    mountingKitType
    mountingKitSize
    mountingKitFamilyDn25
    mountingKitFamilyDn125
    controlUnitSku
    controlUnitClass
    controlUnitSeries
    controlUnitCommunicationProtocol
    controlUnitConnection
    sparePartSKUs
    id
    isAA
    isAseptic
    isBV
    isChangeover
    isDSV
    isDirectConnection
    isDistribution
    isExternalCleaning
    isHygienic
    isManual
    isMixProof
    isMixing
    isNC
    isNoCleaning
    isPMO
    isPneumatic
    isSVP
    isSplitBody
    isSolidBody
    isSample
    isSampleBody
    isSampleInsert
    isSeatliftingCleaning
    isSeveralCleaningMethods
    isSterilization
    isTankBottom
    containsValve
    containsMountingKit
    containsControlUnit
    assets {
      ...ProductAssetsFragment
    }
    connection1
    connection2
    connection3
    samplePartType
    feedback
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
`;

export const productAssetsFragment = gql`
  fragment ProductAssetsFragment on ProductAssets {
    housingImage
    featuredImage {
      name
      link
    }
    sketchfab {
      uid
      name
      link
    }
    documents {
      class
      series
      family
      housingType
      sku
      type
      title
      language
      link
      category
    }
    samplePartTypeImage
  }
`;

export const productFragment = gql`
  ${productAssetsFragment}
  ${productCollectionItemFragment}

  fragment ProductFragment on Product {
    id
    name
    pricing {
      price
      currency
    }
    productType
    class
    series
    family
    housingType
    typeForLaser
    typeSVP
    typeDSV
    sku
    conform3A
    actuation
    actuationType
    actuatorPressureLabelLaser
    additionalMarking
    anbausatzLb
    apzLb
    atex2Lb
    sparePartSKUs
    relatedProducts {
      spareParts {
        ...ProductCollectionItemFragment
      }
    }
    atexLb
    ceLabeling
    changeNo1
    changeNo2
    changeNo3
    changeRequest1
    connection1
    connection2
    connection3
    connectionUnderscore1
    connectionUnderscore2
    crnLabeling
    deliveryTimeKey
    descriptionValveInsertHousing
    drawingNo1
    drawingNo2
    drawingNo3
    entryDate
    furtherPositionFiles
    furtherTemplateFiles
    gostR
    labelLaserStatus
    materialHousing
    materialInsert
    materialKey
    materialNumberHousing
    materialNumberInsert
    sealMaterial
    sealMaterialORing
    materialOfGasket1
    materialOfGasket2
    materialOfGasket3
    measurementProjectEMEA
    operationPressureLabelLaser
    portConfiguration
    positionFileActuator
    positionFileHousing
    qualityClass
    revision1
    revision2
    revision3Lb
    serialNumber
    setOfGasketsAs1Label
    setOfGasketsAs2Label
    setOfGasketsAs3Label
    setOfGasketsPs1Label
    setOfGasketsPs2Label
    setOfGasketsPs3Label
    setOfGasketsPs4Label
    setPressureRange
    sizeLower
    sizeUpper
    sonderKennZeichen
    surface
    tempFileActuator
    tempFileHousing
    windchillNumber1
    windchillNumber2
    windchillNumber3
    skuInactiveStatus
    amountOfSolenoids
    communicationProtocol
    suffixControl
    suffixMounting
    controlUnitSku
    controlUnitClass
    controlUnitSeries
    controlUnitCommunicationProtocol
    controlUnitConnection
    mountingKitSku
    mountingKitType
    mountingKitSize
    mountingKitFamilyDn25
    mountingKitFamilyDn125
    id
    isAA
    isAseptic
    isBV
    isChangeover
    isDSV
    isDirectConnection
    isDistribution
    isExternalCleaning
    isHygienic
    isManual
    isMixProof
    isMixing
    isNC
    isNoCleaning
    isPMO
    isPneumatic
    isSVP
    isSample
    isSampleBody
    isSampleInsert
    isSeatliftingCleaning
    isSeveralCleaningMethods
    isSterilization
    isTankBottom
    isSplitBody
    isSolidBody
    containsValve
    containsMountingKit
    containsControlUnit
    assets {
      ...ProductAssetsFragment
    }
    connection
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
`;

export const listProductsFragment = gql`
  ${productAssetsFragment}
  ${productCollectionItemFragment}

  fragment ProductCollectionFragment on ProductCollection {
    products {
      ...ProductCollectionItemFragment
    }
    pagination {
      limit
      offset
    }
  }
`;

export const listProductsFilterOptionsFragment = gql`
  fragment ProductFilterOptionsFragment on ProductCollection {
    filterOptions {
      productType {
        value
        valueLabel
      }
      class {
        value
        valueLabel
      }
      portConfiguration {
        value
        valueLabel
      }
      sealMaterial {
        value
        valueLabel
      }
      sealMaterialORing {
        value
        valueLabel
      }
      operationPressureLabelLaser {
        value
        valueLabel
      }
      series {
        value
        valueLabel
      }
      sizeUpper {
        value
        valueLabel
      }
      family {
        valueLabel
        value
      }
      housingType {
        value
        valueLabel
        image
      }
      materialInsert {
        value
        valueLabel
      }
      actuation {
        value
        valueLabel
      }
      apzLb {
        value
        valueLabel
      }
      amountOfSolenoids {
        value
        valueLabel
      }
      communicationProtocol {
        value
        valueLabel
      }
      connection {
        value
        valueLabel
      }
      controlUnitSku {
        value
        valueLabel
      }
      controlUnitClass {
        value
        valueLabel
      }
      controlUnitSeries {
        value
        valueLabel
      }
      controlUnitCommunicationProtocol {
        value
        valueLabel
      }
      controlUnitConnection {
        value
        valueLabel
      }
      mountingKitSku {
        value
        valueLabel
      }
      mountingKitType {
        value
        valueLabel
      }
      mountingKitSize {
        value
        valueLabel
      }
      mountingKitFamilyDn25 {
        value
        valueLabel
      }
      mountingKitFamilyDn125 {
        value
        valueLabel
      }
      connection1 {
        value
        valueLabel
      }
      connection2 {
        value
        valueLabel
      }
      connection3 {
        value
        valueLabel
      }
      samplePartType {
        value
        valueLabel
        image
      }
      feedback {
        value
        valueLabel
      }
    }
  }
`;

export const productQRFragment = gql`
  fragment ProductQRFragment on QRInfo {
    id
    serialNumber
    dateOfManufacture
    salesOrderNumber
    productId
    housingType
    productSeries
    portSizes
    actuationType
    sealMaterial1
    sealMaterial2
    sealMaterial3
    material
    surfaceFinish
    settingsRangeOverflowValve
    operatingPressure
    controlAirPressure
    sealKitActuatorSide1
    sealKitActuatorSide2
    sealKitActuatorSide3
    sealKitProductSide1
    sealKitProductSide2
    sealKitProductSide3
    sealKitProductSide4
    exArea
  }
`;
